import { defineStore } from 'pinia'

export const useTabs = defineStore('tabs',{
    state: () => ({

    
    
        reporte:  [
            { id: 'Mes actual', name:'reporte', class:'xs:w-[162px]' },
            { id: 'Mes anterior', name:'reporte1', class:'xs:w-[162px]'},
        ],

      
        
        configuracion:  [
            { id: 'Proyectos y tareas', name:'configuracion', class:'xs:w-[162px]' },
            { id: 'Administradores', name:'configuracion1', class:'xs:w-[162px]'},
        ],

        informacion:  [
            { id: 'Proyecto', name:'informacion', class:'xs:w-[162px]' },
            { id: 'Venta por perfil', name:'informacion1', class:'xs:w-[162px]'},
            { id: 'Proveedor', name:'informacion2', class:'xs:w-[162px]'}
        ],

        detalle:  [
            { id: 'Información', name:'detalle', class:'xs:w-[100px]' },
            { id: 'Comentarios', name:'detalle1', class:'xs:w-[100px]'},
            { id: 'Archivos', name:'detalle2', class:'xs:w-[100px]'},
            { id: 'Historial de la tarea', name:'detalle3', class:'xs:w-[200px]'}
        ],

        crearTarea:  [
            { id: 'Tarea individual', name:'crearTarea' , class:'xs:w-[162px]'},
            { id: 'Por tarea', name:'crearTarea1', class:'xs:w-[162px]'},
        ],

        crearTareaIndividual: [
            { id: 'Mi equipo', name:'crearTareaIndividual', class:'xs:w-[162px]' },
            { id: 'Otros', name:'crearTareaIndividual', class:'xs:w-[162px]'},
        ],

       

        notificaciones: [
            { id: 'Todos', name:'notificaciones', class:'xs:w-[162px]' },
            { id: 'Menciones', name:'notificaciones1', class:'xs:w-[162px]'},
            { id: 'Tarea terminada', name:'notificaciones2', class:'xs:w-[175px]'}
        ],

      
        crearTareaGrupal: [],

   
    }),
    
})

